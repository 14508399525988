import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import logoutImg from "assets/img/icons/logout.svg";
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Button
} from "reactstrap";
import routes from "routes.js";
import { useAuth0 } from "../../react-auth0-spa";
import UserData from "../User/userData";
import FileDetails from '../User/fileDetails';
import UserProfile from '../User/userProfile';
import { Router, Switch, Redirect } from "react-router-dom";
import history from "../../utils/history";

let admin = undefined;
const Header = (props) => {
  // console.log(props)
  // let sidebar = useRef(null);
  let adminUsers = ['balaji', 'clarice', 'john', 'hari', 'santhi', 'sysix1'];
  const { logout } = useAuth0();
  const user = UserProfile.getUserDetails();
  const [resetModal, setResetModal] = useState(false)
  const [resetResult, setResetResult] = useState(undefined)
  const [showingSpin, setShowingSpin] = useState(false);
  const [resetBtn, setResetBtn] = useState(false);
  const [resetConfirmModal, setResetConfirmModal] = useState(false);

  const checkIsAdminUser = () => {
    adminUsers.forEach(name => {
      // console.log(user.name, name)
      if (user.name === name) {
        admin = "is set";
        setResetBtn(admin);
      }
    });
  }

  var getBrand = () => {
    let brandName = "Default Brand";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.name;
      }
      return null;
    });
    return brandName;
  }

  var openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    document.getElementById('sidebar').focus();
  }

  var destroySession = async () => {
    fetch('/logout');
    logout();
    UserProfile.clear();
    FileDetails.clear();
    UserData.clear();
    localStorage.clear();
    sessionStorage.clear();
  }

  const resetDatabases = () => {
    var data = {
      username: user.name
    }
    console.log(data);
    setShowingSpin(true);
    fetch('/resetDatabases', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(data)
    }).then(
      res => {
        setShowingSpin(false);
        if (res.ok) {
          res.json().then(
            res => {
              console.log(res);
              if (res.error) {
                alert("Some error occured at MDM server. \n" + res.error.code);
                return;
              }
              if (res.response.statusCode === 403)
                alert('Something went wrong. Please try again.');

              setResetModal(true);
              setResetResult(res.body.status);
              if (res.body.status === "SUCCESS") {
                FileDetails.clear();
                UserData.clear();
                localStorage.clear();
                sessionStorage.clear();
                sessionStorage.setItem('user', JSON.stringify(user));
                props.history.push({
                  pathname: '/admin/loaddata',
                  state: { refresh: true }
                })
                // window.location.reload(false);
              }
            },
            error => {
              alert(error);
            }
          );
        } else {
          alert(res.statusText + " : " + res.status);
        }
      });
  }

  //console.log(user);
  if (!user) {
    return (
      <Router history={history}>
        <Switch>
          <Redirect to="/login" />
        </Switch>
      </Router>
    );
  }

  if (admin === undefined)
    checkIsAdminUser()

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <div>
      <Navbar
        color="transparent"
        expand="lg"
        className="fixed-top navbar-transparent"
      >
        <Container >
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                // ref={sidebar}
                className="navbar-toggler"
                onClick={() => openSidebar()}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand className="no-cursor">{getBrand()}</NavbarBrand>
          </div>
          <div className="justify-content-end user-details">
            <Nav navbar>
              <NavItem>
                <div className="link-item">
                  <Button className={resetBtn ? "reset-btn" : "reset-btn hide"} onClick={() => setResetConfirmModal(true)}>Reset Databases</Button>
                </div>
              </NavItem>
              <NavItem>
                <div className="link-item">
                  <span> <img src={user.picture} alt="user" /> <span className="user">&nbsp;{user.name}</span></span>
                </div>
              </NavItem>
              <NavItem>
                <div className="link-item">
                  <Button className="logout-link" role="button" onClick={() => destroySession()}> <img src={logoutImg} alt="logout" /> <span className="logout">Logout</span></Button>
                </div>
              </NavItem>
            </Nav>
          </div>
        </Container>
      </Navbar>
      <div className="clearfix"></div>
      <Modal isOpen={resetModal} toggle={() => setResetModal(false)}>
        <ModalHeader toggle={() => setResetModal(false)}>
          Databases Reset Status:
              </ModalHeader>
        <ModalBody>
          {resetResult}
        </ModalBody>
      </Modal>
      <Modal isOpen={showingSpin}>
        <ModalBody>
          <span><i className="fa fa-spinner fa-pulse"></i></span> Resetting databases...
              </ModalBody>
      </Modal>
      <Modal isOpen={resetConfirmModal} toggle={() => setResetConfirmModal(false)} size="lg">
        <ModalHeader toggle={() => setResetConfirmModal(false)}>
          Confirm reset:
              </ModalHeader>
        <ModalBody>
          Are you sure that you want to reset the server database?
              </ModalBody>
        <ModalFooter>
          <Button color="info" className="btnwhite"
            onClick={() => resetDatabases()}>Confirm
                </Button>
          <Button color="info" className="btnwhite" onClick={() => setResetConfirmModal(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Header;
