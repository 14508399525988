import React from "react";
import { MDBDataTable } from 'mdbreact';
// reactstrap components
import {  Card,  CardBody,  Row,  Col,  Button,Input,
  Modal, ModalHeader, ModalBody, Label, FormGroup} from "reactstrap";
import * as d3 from "d3";
import FileDetails from '../components/User/fileDetails';
import UserProfile from '../components/User/userProfile';
import UserData from "../components/User/userData";
import colData from "../utils/columnsList.json";

var update = true;
var data, filename;
var parse, user;
class Loaddata extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      filedata: undefined,
      delimiterModal: false,
      successModal: false,
      delim:undefined,
      createdJobId : undefined,
      showingSpin : false,
      uploadDisabled : false,
      cache: false
    }
    data = undefined;
    filename = undefined;
    user = UserProfile.getUserDetails();
    update = true;
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.createJobAPI = this.createJobAPI.bind(this);
    this.clearData = this.clearData.bind(this);
    this.props.updateScroll();
  }
  showModal(modal) {
    update=true;
    this.setState({
      [modal]: true
    });
  }
  closeModal(tabId) {
    update = true;
    this.setState({
      [tabId]: false
    });
  }
  showData() {
    update = true;
    if(sessionStorage.getItem('loadedData'))
      return false;
    if(this.state.delim){
      parse = d3.dsvFormat(this.state.delim);
      var file =  FileDetails.getFile();
      if(file !== undefined){
        // read the data from file and save as an array
        var reader = new FileReader();
        reader.readAsText(file);
        return reader.onload = () => {
          var dt = parse.parseRows(reader.result)
          // console.log(dt);
          if(dt[0].length === 1){
            alert("wrong delimiter is selected.");
            return false;
          }
          if(dt[0].length >= 5 && dt[0].length <= 8){
            var dtarray = {
                records: 
                  dt.map((arr) => {
                    return arr;
                  }),
                  calling_user: user.name,
                  username: user.name,
                  is_agc : true
              }
              sessionStorage.setItem('loadArray', JSON.stringify(dtarray));
              UserData.setIPWiresList(dtarray);
              this.setState({filedata:dt})
              update = false;
              return true;
          }
          else {
            alert(`Number of columns not matched or wrong delimiter is selected.
            Please note that input file should contain following 5 to 8 columns:
            (ID, ADDRESS1, ADDRESS2, ADDRESS3, ADDRESS4, [Business Category])
            or
            (ID, NAME, STREET, CITY, STATE, ZIP, COUNTRY, [Business Category])`);           
            return false;
          }
        }
      }
    }
    return false;
  }
  clearData() {
    update = true;
    sessionStorage.removeItem('loadedData');
    sessionStorage.removeItem('filename');
    filename = undefined;
    this.setState({delim:undefined, filedata:undefined});
  }
  handleFileUpload(event) {
    event.preventDefault();
    var file = event.target.files[0];
    if(file){
      this.clearData();
      FileDetails.setFile(file);
      event.target.value="";
      sessionStorage.setItem('filename', file.name);
      FileDetails.setFileName(file.name);
      this.showModal('delimiterModal');
    }
  }
  createJobAPI() {
    var ipWires = UserData.getIPWiresList();
    if(ipWires === undefined || ipWires.records.length === 0)
      alert("No Wires list available.");
    else {
      ipWires.use_cache=this.state.cache;
      //checking number of columns to find Business category exists or not
      var api = ipWires.records[0].length === 6 ? '/createBCJob' : '/createJob';
      update = true;
      this.setState({showingSpin : true, uploadDisabled : true});
      fetch(api, {
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(ipWires)}).then(
        res => {
         if(res.ok){
            res.json().then( res => {
              console.log(res);
              if(res.error){
                alert("Some error occured at MDM server. \n" + res.error.code);
                this.setState({ showingSpin: false, uploadDisabled: false });
              }
              else{
                if(res.stager_list.statusCode === 403){
                  alert('Something went wrong. Please try again.');
                  this.setState({ showingSpin: false, uploadDisabled: false });
                }
                else{
                  UserData.setNewJobId(res.body.job_id);
                  this.setState({createdJobId:res.body.job_id,
                                 successModal:true,
                                 uploadDisabled: false,
                                 showingSpin:false});
                }
              }
            }, error => {
                alert(error);
                this.setState({ showingSpin: false, uploadDisabled: false });
            });  
          }else{ 
            alert(res.statusText + " : " + res.status);
            this.setState({ showingSpin: false, uploadDisabled: false });
          }      
        }
      );
    }
  }
  handleCheckBox = (e) => {
    update=true;
    this.setState({cache: e.target.checked});
  }
  handleChange(event) {
    this.closeModal('delimiterModal');
    this.setState({delim:event.target.value});
  }
  shouldComponentUpdate(){
    return update;
  }

  render() {
    var showdata = this.showData();
    var dataExists = false;
    if(showdata) {
      var fileData = this.state.filedata;
      if(fileData !== undefined){
        var numc = fileData[0].length;
        var headers = colData[numc];
        // console.log(headers);
        data = {
          columns:
             headers.map(header => {
                return {label: header.label,  field: header.field}
              }),

          rows: 
            fileData.map((arr,key) => 
              {
                var row = {key};
                for(let i=0; i < numc; i++)
                  row[headers[i].field] =  arr[i] ? arr[i] : '-';
                
                return row;           
              })        
        }
        // console.log(data);
        sessionStorage.setItem('loadedData', JSON.stringify(data));
      }
    }
    else
      data = sessionStorage.getItem('loadedData')? JSON.parse(sessionStorage.getItem('loadedData')) : undefined;
  
    if(data && !this.state.showingSpin) {
      dataExists=true;
      filename = sessionStorage.getItem('filename');
    }
    
    return (       
        <React.Fragment>
          <div className={this.state.showingSpin ? "loading" : "loading hide"}>
            <i className="fa fa-spinner fa-pulse"></i>
          </div>

          <Row className="mb-3">
            <div className="col-md-4 mt-3 pl-4">
              <label>File Uploaded : </label>
              <span className="namestyle">{filename}</span>
            </div>
            <div className="col-md-8 pl-4 justify-content-end" style={{display:"inline-flex"}}>
              <div className='custom-control custom-switch' >
                <input
                  type='checkbox'
                  className='custom-control-input'
                  id='customSwitches'
                  onChange={e => this.handleCheckBox(e)}
                  checked={this.state.cache}
                  readOnly
                />
                <label className='custom-control-label' htmlFor='customSwitches'>
                  Use cache
                </label>
              </div>
              {/* <CustomInput type="checkbox" id="cache" label="Use Cache" inline 
                          onChange={e => this.handleCheckBox(e)} checked={this.state.cache}/> */}
              <div className={this.state.uploadDisabled ? "file is-blue uploadDisabled" : "file is-blue"}>
                <label className="file-label">
                  <input className="file-input" type="file"  name="Mydata" title="" onChange={this.handleFileUpload} />
                  <span className="file-cta">
                    <span className="file-label">
                      Upload Data
                    </span>
                  </span>
                </label>
              </div>
              <Button color="success" disabled={!dataExists} onClick={this.createJobAPI} className="runmdm">RUN ADDRESS GOLD COPY</Button>             
            </div>
          </Row>
          
          <Row>
            <Col md="12" >
              <Card>              
                <CardBody>
                <MDBDataTable
                  entriesOptions={[5, 10, 15, 20, 25, 50, 100]}
                  entries={15}
                  striped
                  hover
                  onPageChange = {(value) => this.props.updateScroll()}
              //    sort = {['id']}
                  data={data} className="bordered"
                />
                </CardBody>
              </Card>
                <Button color="primary" className="pull-right" onClick={this.clearData} disabled={!dataExists}>Clear Data</Button>
            </Col>
          </Row>
          <Modal isOpen={this.state.delimiterModal} backdrop="static">
            <ModalBody>
              <FormGroup row>
                <Label className="control-label" sm={4}>Select Delimiter</Label>
                <Col sm={8}>
                  <Input type="select" name="select" value="" onChange={this.handleChange}>
                    <option value="" >select delimiter</option>
                    <option value=",">, (comma)</option>
                    <option value=";">; (semi colon)</option>
                    <option value="|">| (pipe)</option>
                  </Input>
                </Col>
              </FormGroup>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.successModal} toggle={this.closeModal.bind(this, 'successModal')}>
            <ModalHeader toggle={this.closeModal.bind(this, 'successModal')}>
              Job Submitted Sucessfully
            </ModalHeader>
            <ModalBody>
                Job id: {this.state.createdJobId}
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }
  }
export default Loaddata;