import React from "react";
import { Table, Input } from "reactstrap";
import MultiSelect from "./MultiSelect";
// import MultiSelect from "react-multi-select-component";
// import Select from 'react-select';
var searchValues={};
const fieldTypes = {
  job_id:{fieldType:'text'},
  job_name:{fieldType:'text'},
  sl_id:{fieldType:'text'},
  types:{fieldType:'select'},
  start_date:{fieldType:'date'},
  end_date:{fieldType:'date'}
}
class AdvancedSearch extends React.Component {
    constructor (props) {
      super(props);
      this.state={
        rowItems : ['job_id'],
      }

      this.searchoptions = [
        {field:'Job Id', value:'job_id'},
        {field:'Job Name',value:'job_name'},
        {field:'SL_ID',value:'sl_id'},
        {field:'Types', value:'types'},
        {field:'Start date', value:'start_date'},
        {field:'End date', value:'end_date'}
      ];
    }    
    
    addNewSearchOption = () => {
      var selected = Object.keys(searchValues);
      var remaining =this.searchoptions.filter(opt => !selected.includes(opt.value));
      var first = remaining[0];
      var rItems = this.state.rowItems;
      rItems.push(first.value);
      searchValues[first.value]=null;
      // console.log(rItems)
      this.setState({rowItems:rItems});
    }

    deleteSearchItem = (event) => {
      var tr = event.target.parentElement.parentElement;
      // var trs = document.getElementById("searchTable");
      var value = tr.firstChild.firstChild.value;
      delete searchValues[value];

      var rItems = this.state.rowItems;
      var deleteInd;
      rItems.forEach((item, index) => {
        if(item === value)
          deleteInd = index;
      })
      rItems.splice(deleteInd,1);
      // console.log(rItems);
      this.setState({rowItems:rItems}); 
    }
    
    getSelectedTypes = (types) => {      
      var stypes = types.map(type => {
        return type.value;
      })
      // console.log(stypes);
      searchValues.types = stypes;
    }

    handleSelectedSearchItem = (event) => {
      var sValue = event.target.value;
      if(Object.keys(searchValues).includes(sValue)){
        alert(sValue + " is already selected in another row.");
        event.target.value = event.target.old;
        return;
      }
      var old = event.target.old;
      delete searchValues[old];

      var rItems = this.state.rowItems;
      rItems.forEach((item, index) => {
        if(item === old)
          rItems[index] = sValue;
      })
      
      // console.log(rItems);
      searchValues[sValue] = undefined;
      this.setState({rowItems:rItems});
    }

    getPreviousSelectedValue = (event) => {
      event.target.old = event.target.value;
    }

    handleSearchInput = (event) => {
      var ele = event.target;
      var item = event.target.parentElement.previousElementSibling.firstChild.value;
      searchValues[item]=ele.value;
      // console.log(searchValues);
    }
    
    componentDidMount(){
      searchValues = {};
      searchValues.job_id = this.props.jobid;
      this.props.sendSearchItems(searchValues);
    }
    render() {   
      fieldTypes.job_id.defaultValue = this.props.jobid;
        return (
            <Table borderless>
              <tbody id="searchTable" ref={this.tbodyRef}>
                {
                  this.state.rowItems.map((item, ind) => {
                    var fieldType = fieldTypes[item].fieldType;
                    return  <tr key={item}>
                        <td id={"td"+ind}>
                          <Input type="select" id={"select"+ind} className="custom-dd" onChange={e => this.handleSelectedSearchItem(e)} 
                                  onClick={e => this.getPreviousSelectedValue(e)} old="" defaultValue={item}>
                            {
                              this.searchoptions.map((opt, i) => {
                                return <option value={opt.value} key={i}>{opt.field}</option>
                              })
                            }
                          </Input>
                        </td>
                        <td id={"input-td"+ind}>
                          { (fieldType === 'text' || fieldType === 'date') &&
                              <Input type={fieldType} onChange={e => this.handleSearchInput(e)}
                                      defaultValue = {fieldTypes[item].defaultValue}/>
                          }
                          {
                            fieldType === 'select' &&
                            <MultiSelect sendSelectedTypes={this.getSelectedTypes}></MultiSelect>
                          }
                        </td>
                        <td onClick={e => this.addNewSearchOption()}><i className="fas fa-plus-circle blue-circle"></i></td>
                        <td className={ind===0? "hide": ""} onClick={(e) => this.deleteSearchItem(e)}><i className="fas fa-minus-circle red-circle"></i></td>
                      </tr>
                  })
                }
                             
              </tbody>
            </Table>
        )
    }
}

export default AdvancedSearch;