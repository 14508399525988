import React from "react";
// reactstrap components
import { Row, Col, Button } from "reactstrap";
//import { Link } from 'react-router-dom';

import { useAuth0 } from "../react-auth0-spa";

const Login = () => {

    // Get loginWithRedirect function from Auth0Context
    const { loginWithRedirect } = useAuth0();

    return (
      <>
        <div className="login-box">
          <Row>
            <Col md={12}>             
              <div className="mt-10 p-10">
                <h2 className="text-green text-center p-10 text-b"> Address Gold Copy</h2>
                <h6 className="welcome-note"> Welcome Back, Please login to your Gold Copy Account</h6>
                <Row>
                  <Col md={{ size: 10, offset: 1 }}>
                        <Button className="rounded-btn btn" onClick={() => loginWithRedirect({})}>Log in</Button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }

export default Login;
