
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import DemoNavbar from "components/Navbars/DemoNavbar.jsx";
import Loaddata from "views/Loaddata";
import Results from "views/Results";
import Jobs from "views/Jobs";
import GeoSearch from "views/GeoSearch";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import NotFound from "views/NotFound";
import routes from "routes.js";

// renders the admin layout with Loaddata as application home page
var ps;
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      activeColor: "info",
      load:''      
    };
    this.tableStyle = {
      marginTop : "100px",
      marginLeft: "10px"
    }
    this.mainPanel = React.createRef();
  }
  // applies perfect scroll bar whenever the component is mounted
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  // destroys the perfect scroll bar whenever the component is unmounted.
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.body.classList.toggle("perfect-scrollbar-on");
    }
  }

  // Can prevent scrolling at unnecessary events.
  preventScrolling = () => {
    var curPosition = this.mainPanel.current.scrollTop;
    this.mainPanel.current.scrollTop = curPosition;
  }

  // updates the scroll bar at required events.
  updateScrollBar = () => {
    if(this.mainPanel.current)
      this.mainPanel.current.scrollTop = 0;
  }

 
  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}         
        />
        <div className="main-panel" ref={this.mainPanel}>
          <DemoNavbar {...this.props}/>   
          <div className="content">
            <Switch>
              <Route path='/admin/loaddata' render={(props) => (<Loaddata {...props} updateScroll={this.updateScrollBar} />)}/>
              <Route path='/admin/results' render={(props) => (<Results {...props} updateScroll={this.updateScrollBar} preventScroll={this.preventScrolling} />)}/>
              <Route path='/admin/jobs' render={(props) => <Jobs {...props} updateScroll={this.updateScrollBar}/>}/>
              <Route path='/admin/geosearch' render={(props) => (<GeoSearch {...props} updateScroll={this.updateScrollBar}/>)}/>
              <Route component={NotFound}/>           
            </Switch>     
          </div>
        </div>       
      </div>
    );
  }
}

export default Dashboard;
