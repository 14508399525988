import React from "react";
import { Button } from "reactstrap";

var lastPage;
var pageIndex, total, perPage, start, end;
class Pagination extends React.Component {
    // constructor(props) {
    //     super(props);
    //     // const { pageIndex, total, perPage, onNext, onPrevious } = this.props;
     
    // }

    pageUpdate = () => {       
        pageIndex = this.props.pageIndex;
        total = this.props.total;
        perPage  = Math.min(this.props.perPage, total);
        lastPage = Math.ceil(total / perPage);
        start = (pageIndex-1) * perPage + 1;
        end = Math.min((pageIndex * perPage),total);
    }

    render() {
        this.pageUpdate();
        return (
            <div className="pagination">
                <div className="col-8 page-stats">
                    {  total > 0 &&
                        <span>Showing {start} to {end} of {total} records</span>
                    }
                </div>
                <div className="col-4 page-actions">
                    <Button onClick={this.props.onNext} title="Next" disabled={pageIndex >= lastPage}>&gt;&gt;</Button>
                    <Button onClick={this.props.onPrevious} title="Previous" disabled={pageIndex <= 1}>&lt;&lt;</Button>
                </div>               
            </div>
        );
    }
}
export default Pagination;