import React from "react";
import MultiSelect from "react-multi-select-component";
import matchtypes from "../utils/match_types.json";

// var match_types = matchtypes.types;
class SelectMultiTypes extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            types:[]
        }
    }

    setMultipleTypes = (values) => {
        this.setState({ types: values });
        this.props.sendSelectedTypes(values);
    }

    render() {
        return (
            <MultiSelect
                className = "custom-dd"
                options={matchtypes.types}
                value={this.state.types}
                onChange={vals => this.setMultipleTypes(vals)}
                labelledBy={"Select"}
                overrideStrings={{"selectSomeItems":"Select match_types..."}}
            />
        )
    }
}

export default SelectMultiTypes;