

import Loaddata from "views/Loaddata";
import Results from "views/Results";
// import CorridorReport from "views/CorridorReport.jsx";
import Jobs from "views/Jobs";
import GeoSearch from "views/GeoSearch";

var routes = [
  {
    path: "/loaddata",
    name: "Load Data",
    icon: "nc-icon nc-cloud-upload-94",
    component: Loaddata,
    layout: "/admin"
  },
  
  {
    path:"/jobs",
    name:"Jobs",
    icon:"nc-icon nc-bullet-list-67",
    component:Jobs,
    layout:"/admin"
  },
  {
    path: "/results",
    name: "Results",
    icon: "nc-icon nc-chart-bar-32",
    component: Results,
    layout: "/admin"
  },
  
  // {
  //   path: "/corridorreport",
  //   name: "Corridor Report",
  //   icon: "nc-icon nc-report",
  //   component: CorridorReport,
  //   layout: "/admin"
  // },
  
  {
    path:"/geosearch",
    name:"Geo Search",
    icon:"nc-icon nc-pin-3",
    component:GeoSearch,
    layout:"/admin"
  }
];
export default routes;
