import React from 'react';
import Dashboard from "layouts/Admin";
import LoginLayout from "layouts/LoginLayout";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
// import UserData from "./components/User/userData";
//import UserProfile from './components/User/userProfile';

var App = function() {
  var redirect;
  const { loading, isAuthenticated, user} = useAuth0();
  if (loading) {
	  return <div className="loading" style={{marginLeft:"50%"}}><i className="fa fa-spinner fa-pulse"></i></div>;
  }
  
  if(!isAuthenticated)
    redirect = "/login";
  else{
    redirect = "/admin/loaddata";
    console.log(user);
  }

  // return the proper layout
   return (
    <Router history={history}>
    <Switch>
      <Route path="/admin" render={props => <Dashboard {...props} />} />
      <Route path="/login" render={props => <LoginLayout {...props} />} />
      <Redirect to={redirect} />
    </Switch>
  </Router>
  );
}

export default App;