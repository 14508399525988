var FileDetails = (function(){
    var file = undefined;
    var filename = sessionStorage.getItem('filename') || undefined;
    var setFile = function(fileObj){
        file = fileObj;
    }

    var getFile = function(){
        return file;
    }

    var setFileName = function(fname){
        filename = fname;
    }

    var getFileName = function(){
        return filename;
    }

    var clear = function(){
        file = undefined;
    }

    return {
        getFile: getFile,
        setFile: setFile,
        setFileName: setFileName,
        getFileName: getFileName,
        clear: clear
    }
})();

export default FileDetails;
