import React from 'react';
import UserProfile from '../components/User/userProfile';
import {
  Card, Row, Col, Modal, ModalHeader, ModalBody,
  FormGroup, Label, Input, CardTitle, CardBody, Table, CustomInput
} from "reactstrap";
import LeafletOSM from './leafletOSM';
import PerfectScrollbar from "perfect-scrollbar";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import history from '../utils/history';

var user, ps, inputSLID, newSLID=false;
var markers = [], geoResult=undefined, radiuses=["500", "1000", "1500", "2000"];
var merchants = ["sysix", "client", "similar"];
var visited ={};
class GeoSearch extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          searchInput : (this.props.location.state) ?
                         this.props.location.state.sysixid : 
                        (sessionStorage.getItem('geoSearchIP')? sessionStorage.getItem('geoSearchIP') :''),
          showingSpin : false,
          searchUpdated: false,
          markersUpdated: false,
          searchRadius : sessionStorage.getItem('searchRadius') || radiuses[0],
          sysix: true,
          client: false,
          similar: false,
          searchInList:[],
          inputType: 'SLID',
          infoModal: false,
          merchantsInfo: ""
        
      }
      this.timer = null;
    
      // var mapCardRef = React.createRef();
      inputSLID = sessionStorage.getItem('inputSLID');
      this.variationsRef = React.createRef();
      if (this.props.location.state) {
        if (this.props.location.state.sysixid) {
          newSLID = true;
        }
      }
      markers = [];
      user = UserProfile.getUserDetails();
      this.capitalizeString = this.capitalizeString.bind(this);
      this.getAutoCompleteList = this.getAutoCompleteList.bind(this);
      this.props.updateScroll();
    }

    componentWillMount(){
      var prevResult = sessionStorage.getItem('geoResult');
      if(this.state.searchInput && newSLID)
        this.initializeGoldResult();
      else if(prevResult){
        var sysix=false, client=false, similar=false;
        geoResult = JSON.parse(prevResult);
      //  console.log(geoResult);
        markers = sessionStorage.getItem('markers');
        markers = JSON.parse(markers);
        if(markers.filter(mark => mark.type==='sysix').length > 0)
          sysix = true;
        if(markers.filter(mark => mark.type==='client').length > 0)
          client = true;
        if(markers.filter(mark => mark.type==='similar').length > 0)
          similar = true;
        this.setState({searchUpdated:true, sysix, client, similar});
      }
      else{
        this.initializeGoldResult();
      }

      // initialize merchants checkbox status
      // this.initializeMerchantCheckboxes();
    }

    componentDidMount() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps = new PerfectScrollbar(this.variationsRef.current);
      }
      // const history = createHistory();
      if (history.location.state && history.location.state.sysixid) {
          let state = undefined;
          history.replace({ ...history.location, state });
      }

        // initialize visited merchants status
        visited = JSON.parse(sessionStorage.getItem('visited'));
        if(!visited){
          visited ={};
          merchants.forEach(mtype => {
            var obj = {};
            radiuses.forEach(radius => {
              obj[radius]=false;
            });
            visited[mtype]=obj;
          });
        }
        else {
          this.applyVisitedToCheckboxes(this.state.searchRadius);
        }
        console.log(visited);
    }

    initializeMerchantCheckboxes() {
      sessionStorage.setItem('visited', null);
      var checkBoxes = document.getElementById("MerchantOptions");
      if(checkBoxes){
        var checkList = checkBoxes.children;
        checkList.forEach(customElement => {
          var inputElement = customElement.firstChild;           
          customElement.title="";
          inputElement.classList.remove("visited");
        });
      }
    }

    closeModal(tabId) {
      this.setState({
        [tabId]: false
      });
    }

    initializeGoldResult() {
      // initialize gold result object
      geoResult = {
        gold_copy : {},
        kyc:{
          kyc_attributes:{}
        },
        variations:[],
        related_news:[]
      }
    }
    handleAutoComplete = (value) => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getAutoCompleteList(value)
      }, 100)
    }

    onInputChange = (e, value) => {
      //  console.log(value);  
      if(e)  
        if(value){
          if(e.keyCode !== 13){
            // console.log(value);
            this.handleAutoComplete(value)
          }
        }
        else{
          this.setState({searchInList:[]})
        }
    }

    handleRadiusChange = (e) => {
      this.props.updateScroll();
      var field = e.target.name;
      var radius = e.target.value;
      this.setState({[field]:radius});
      sessionStorage.setItem('searchRadius', radius);  
      setTimeout(() => {
        this.applyVisitedToCheckboxes(radius);
      }, 200); 
    }

    applyVisitedToCheckboxes (radius) {
      var checkList = document.getElementById("MerchantOptions");
      if(checkList)
        checkList = checkList.children;
      if(checkList){
        checkList.forEach(customElement => {
          var inputElement = customElement.firstChild;
          var inputId = inputElement.id;
          customElement.title="";
          inputElement.classList.remove("visited");
          if(inputElement.checked)
            this.handleMerchantsCheckBox(null, inputElement.id);
          else
            if(visited[inputId][radius]){
              inputElement.classList.add("visited");
              customElement.title="visited";
            }
        });
      }
    }

    capitalizeString(text){
      var textArray = text.split('_');
      console.log(textArray);
      var word = textArray[1];
      console.log(word);
      var c = word.charAt(0);
      word = word.replace(c, c.toUpperCase());
      return word;
    }

    async getAutoCompleteList(input){
      var input_data = {
        input_name: input.toUpperCase(),
        num_results: undefined
      }
      var request = {
          data: input_data,
          username: user.name
      }
      this.setState({showingSpin:true})
      await fetch('/getGeoAutoComplete', {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(request),
        method: "POST"}
      ).then(
        res => {
          if(res.ok) {
            res.json().then(
              res => {
                console.log(res);
                if(res.error){
                  alert("Some error occured at MDM server. \n" + res.error.code);   
                  this.setState({showingSpin:false});
                }
                else if(res.body.autocomplete_output_list){
                  this.setState({showingSpin:false, searchInList:res.body.autocomplete_output_list.filter(opt => opt.name !== "")});
                }
                else{
                  alert('No data found')
                  this.setState({showingSpin:false});
                }
              },
              error => {
                alert(error);
                this.setState({showingSpin:false});
              }
            );
          } else {
            alert(res.statusText + " : " + res.status);
            this.setState({showingSpin:false});
          }
        }
      );
    }

    getGeoData = (value) => {
      if(!value)
        return;
      this.initializeGoldResult();
      this.initializeMerchantCheckboxes();
      this.setState({showingSpin:true, searchUpdated:false, searchRadius:radiuses[0],
         sysix:true, client:false, similar:false});
      newSLID = false;
      var input_data = {
          input_name: value,
      //    input_type: value? 'GENERAL' : this.state.inputType,
          use_sample: false
      }
      var request = {
          data: input_data,
          username: user.name
      }
      markers = [];
      inputSLID = value;
      this.props.updateScroll();
      sessionStorage.setItem('inputSLID', inputSLID);
      // this.setState({showingSpin:true, searchUpdated:false, searchRadius:radiuses[0]})
      fetch('/getGeoData', {
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
              },
              body:JSON.stringify(request),
              method: "POST"}
        ).then(
        res => {
          // this.setState({showingSpin:false});
          if(res.ok) {
            res.json().then(
              res => {
                console.log(res);
                if(res.error){
                  alert("Some error occured at MDM server. \n" + res.error.code);   
                  this.setState({showingSpin:false});
                }
                else if(res.body.gold_copy && res.body.gold_copy.gold_name){
                  geoResult = res.body;
                  var mark = {...geoResult.gold_copy};
                  mark.latitude = geoResult.geo.lat;
                  mark.longitude = geoResult.geo.long;
                  mark.type = "gold";
                  mark.sl_id = geoResult.sl_id;
                  markers.push(mark);
                  sessionStorage.setItem('geoResult', JSON.stringify(geoResult));
                  sessionStorage.setItem('markers', JSON.stringify(markers));
                  sessionStorage.setItem('geoSearchIP', this.state.searchInput);
                  this.setState({searchUpdated:true, markersUpdated:!this.state.markersUpdated,
                    showingSpin:false, Client:false, Similar:false})
                  this.handleMerchantsCheckBox(null, 'sysix');
                }
                else{
                  // alert('No data found')
                  this.setState({showingSpin:false, infoModal:true, merchantsInfo:"No data found"});
                }
              },
              error => {
                alert(error);
                this.setState({showingSpin:false});
              }
            );
          } else {
            alert(res.statusText + " : " + res.status);
            this.setState({showingSpin:false});
          }
      });
    }

    handleMerchantsCheckBox = (event, type) => {
      var ele = undefined;
      markers = markers.filter(marker => marker.type!==type)
      if(event){
        ele = event.target;
        if(!ele.checked){         
          sessionStorage.setItem('markers', JSON.stringify(markers));
          this.setState({markersUpdated:!this.state.markersUpdated, [type]:false});
          return;
        }
      }
      else
        ele = document.getElementById(type);

      var input_data = {
        sl_id: inputSLID,
        radius: this.state.searchRadius,
        category: ""
      }
      var request = {
          data: input_data,
          username: user.name
        }
      this.setState({showingSpin:true, [type]:true})
      fetch('/'+type+'MerchantsInfo', {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body:JSON.stringify(request),
            method: "POST"}
      ).then(
      res => {
        // this.setState({showingSpin:false});
        if(res.ok) {
          res.json().then(
            res => {
              console.log(res);
              if(res.error){
                alert("Some error occured at MDM server. \n" + res.error.code);  
                this.setState({[type]:false, showingSpin:false})          
              }
              else{
                if(res.body.addresses && res.body.addresses.length > 0){
                  // filter gold merchant from set of other type merchants
                  var addrs = res.body.addresses.filter(add => add.sl_id !== markers[0].sl_id);
                  // if(type === "sysix")
                  //   var addrs = res.body.addresses.filter(add => add.sl_id !== markers[0].sl_id);
                  // else if(type === "client"){
                  //   var goldID = parseInt(markers[0].sl_id.slice(3));
                  //   addrs = res.body.addresses.filter(add => add.sl_id !== goldID);
                  // }
                  if(addrs.length > 0){
                    addrs.forEach( (add) => {
                      var mark = {...add};
                      mark.type = type;
                      markers.push(mark);  
                    }) 
                    visited[type][this.state.searchRadius]=true;
                    this.setState({markersUpdated:!this.state.markersUpdated, [type]:true, showingSpin:false})  
                  }
                  else {
                    if(ele){
                      ele.classList.add("visited");
                      ele.parentElement.title = "visited";
                    }
                    visited[type][this.state.searchRadius]=true;
                    this.setState({[type]:false, showingSpin:false, infoModal:true,
                         merchantsInfo:'No '+ type + ' merchants are found.'});
                  }        
                }
                else{
                  if(ele){
                    ele.classList.add("visited");
                    ele.parentElement.title = "visited";
                  }
                  visited[type][this.state.searchRadius]=true;
                  this.setState({[type]:false, showingSpin:false, infoModal:true,
                       merchantsInfo:'No '+ type + ' merchants are found.'});
                }
                sessionStorage.setItem('markers', JSON.stringify(markers));
                sessionStorage.setItem('visited', JSON.stringify(visited));
               
                // console.log(markers);              
              }
            },
            error => {
              alert(error);
              this.setState({[type]:false, showingSpin:false})
            }
          );
        } else {
          alert(res.statusText + " : " + res.status);
          this.setState({[type]:false, showingSpin:false})
        }
      });
    }
  
    // componentDidUpdate(prevProps, prevState){
    //   if(this.variationsRef.current){
    //     ps = new PerfectScrollbar(this.variationsRef.current, {
    //       suppressScrollX: true
    //     });
    //   }
    // }
    componentWillUnmount() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    } 
    render(){
        // console.log(markers);
        if(this.state.searchInput && newSLID)
          this.getGeoData(this.state.searchInput);
        // console.log(this.state.searchInList);
        return(
            <div className="geo-intel-content">
              <div className={this.state.showingSpin ? "loading" : "loading hide"}>
                  <i className="fa fa-spinner fa-pulse"></i>
              </div>
              <Row id="auto-search">
                <Autocomplete
                  freeSolo
                  id="auto-complete"
                  name="searchInput"
                  disableClearable
                  value={this.state.searchInput}
                  defaultValue={this.state.searchInput}
                  options = {this.state.searchInList}
                  getOptionLabel={(option) => {
                      if(option.name)
                        return option.address + ', ' + option.sl_id
                      else
                        return option
                    }
                  }
                  onChange = {(e, value) => {
                      if(value.constructor === Object){
                        this.setState({searchInput: value.address+', '+value.sl_id});
                        this.getGeoData(value.sl_id);
                      }else{
                        this.setState({searchInput:value});
                        this.getGeoData(value);
                      }                     
                    }
                  }
                  filterOptions={(options, state) => options}
                  onInputChange={(e, value) => {this.onInputChange(e, value)}}
                  clearOnEscape={true}
                  blurOnSelect={true}
                  onBlur={(e) => {
                      if(!e.target.value)
                        this.setState({searchInList:[]})
                    }
                  }
                  renderInput = {(params) => (
                    <TextField
                      {...params}
                      label="Search for a merchant.."
                      margin="normal"
                      variant="outlined"
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
              </Row>
              <div id="resultArea" className={this.state.searchUpdated? "" : "hide"}>
              <Row>
                <Col md="5">
                  <Card body className="">
                    <CardTitle>Gold Copy</CardTitle>
                    <CardBody>
                      <Table className="gold-copy-details" style={{tableLayout: "fixed"}}>
                        <tbody>
                          {
                            geoResult.gold_copy.gold_name && 
                            <tr>
                              <td>Name:</td>
                              <td>{geoResult.gold_copy.gold_name}</td>
                            </tr>
                          }
                          {
                            geoResult.gold_copy.gold_address && 
                            <tr>
                              <td>Address:</td>
                              <td>{geoResult.gold_copy.gold_address}</td>
                            </tr>
                          }
                          {
                            geoResult.gold_copy.gold_phone && 
                            <tr>
                              <td>Phone:</td>
                              <td>{geoResult.gold_copy.gold_phone}</td>
                            </tr>
                          }
                          {
                            geoResult.gold_copy.gold_website && 
                            <tr>
                              <td>Website:</td>
                              <td><a href={geoResult.gold_copy.gold_website} target="_blank" rel="noopener noreferrer">
                                {geoResult.gold_copy.gold_website}</a>
                              </td>
                            </tr>
                          } 
                          {
                            geoResult.gold_copy.category && 
                            <tr>
                              <td>Category:</td>
                              <td className="busi-category">{geoResult.gold_copy.category}</td>
                            </tr>
                          }           
                        </tbody>
                      </Table>
                    </CardBody>
                  </Card>
                  <Card body className="">
                    <CardTitle>Variations</CardTitle>
                      <CardBody>
                        <div className="variationsBody" ref={this.variationsRef} >
                          <Table className="gold-copy-details">
                            <tbody>
                              {
                                geoResult.variations.map((variate, ind) => {
                                  return  <tr key={ind}>
                                            <td>{variate.init_name} <br />{variate.init_address}</td>
                                            <td><span className="text-btnblue-ul">{variate.status}</span></td>
                                            <td><span className="limitedText" title={variate.job_id}>{variate.job_id}</span></td>
                                          </tr>
                                })
                              }
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                   {/* <Button>Go somewhere</Button> */}
                  </Card>
                  { !!geoResult.kyc &&
                  <Card body className="">
                    <CardTitle>KYC</CardTitle>
                      <CardBody>
                        <div className="" >
                          <Table className="gold-copy-details">
                            <tbody>
                              {
                                geoResult.kyc.aliases && !!geoResult.kyc.aliases.length && (
                                  <tr>
                                    <td>Aliases:</td>
                                    <td>{geoResult.kyc.aliases.map((alias) => (<><span key={alias}>{alias}</span><br/></>))}</td>
                                  </tr>
                                )
                              }
                              {
                                geoResult.kyc.hours && (
                                  <tr>
                                    <td>Hours:</td>
                                    <td>{geoResult.kyc.hours}</td>
                                  </tr>
                                )
                              }
                              {
                                geoResult.kyc.links && !!geoResult.kyc.links.length && (
                                  <tr>
                                    <td>Links:</td>
                                    <td>{geoResult.kyc.links.map((link) => (<><a href={link} target="_blank" rel="noopener noreferrer">{link}</a><br /></>))}</td>
                                  </tr>
                                )
                              }
                              {
                                geoResult.kyc['more-info'] && !!geoResult.kyc['more-info'].length && (
                                  <tr>
                                    <td>More Info:</td>
                                    <td>{geoResult.kyc['more-info'].map(link => (<><a href={link} target="_blank" rel="noopener noreferrer">{link}</a><br /></>))}</td>
                                  </tr>
                                )
                              }
                              {
                                geoResult.kyc['payment-method'] && !!geoResult.kyc['payment-method'].length && (
                                  <tr>
                                    <td>Payment Methods:</td>
                                    <td>{geoResult.kyc['payment-method'].map(payment => (<><span>{payment}</span><br /></>))}</td>
                                  </tr>
                                )
                              }
                              {
                                geoResult.kyc.phones && !!geoResult.kyc.phones.length && !!geoResult.kyc.phones[0] && (
                                  <tr>
                                    <td>Other Phones:</td>
                                    <td>{geoResult.kyc.phones.map((phone) => (<><span key={phone}>{phone}</span><br/></>))}</td>
                                  </tr>
                                )
                              }
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                  </Card>
                  }
                </Col>
                <Col md="7">
                  <Card body  id="mapCard" className="map-style">
                    <CardBody>
                      {
                        markers.length > 0 && 
                        <LeafletOSM markers={markers} radius={this.state.searchRadius}/>
                      }
                    </CardBody>
                  </Card>
                   <FormGroup>
                    <Row className="padding2">
                      <Col md="3">
                        <Label for="exampleCheckbox" >Select Merchants: </Label>
                      </Col>
                      <Col md="9" id="MerchantOptions">
                        <CustomInput type="checkbox" id="sysix" className="sysix" label="Sysix Merchants" inline 
                            onChange={e => this.handleMerchantsCheckBox(e,'sysix')} checked={this.state.sysix}/>
                        <CustomInput type="checkbox" id="client" className="client" label="Client Merchants" inline 
                            onChange={e => this.handleMerchantsCheckBox(e, 'client')} checked={this.state.client}/>
                        {/* <CustomInput type="checkbox" id="similar" className="similar" label="Similar Merchants" inline 
                            onChange={e => this.handleMerchantsCheckBox(e, 'similar')} checked={this.state.similar}/> */}
                      </Col>
                    </Row>
                    <Row className="padding2">
                      <Col md="3">
                        <Label className="control-label">Select Radius: </Label>
                      </Col>
                      <Col md="4">
                        <Input type="select" name="searchRadius" className="radiusDropdown"
                          value={this.state.searchRadius}
                           onChange={e => this.handleRadiusChange(e) }>
                            {
                              radiuses.map((rad, ind) => {return <option value={rad} key={ind} >{rad}</option>})
                            }
                          {/* <option value="500" >500</option>
                          <option value="1000">1000</option>
                          <option value="1500">1500</option>
                          <option value="2000">2000</option> */}
                        </Input>
                      </Col>
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <Modal isOpen={this.state.infoModal} toggle={this.closeModal.bind(this, 'infoModal')}>
                <ModalHeader toggle={this.closeModal.bind(this, 'infoModal')}>
                </ModalHeader>
                <ModalBody>
                {this.state.merchantsInfo}
                </ModalBody>
            </Modal>
            
          </div>
        )
    }
}
export default GeoSearch;
