import React from "react";
import { MDBDataTable } from 'mdbreact';
import "react-datepicker/dist/react-datepicker.css";
// reactstrap components
import {
  Card, CardBody, CardFooter, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button,
  Form, FormGroup, Input
} from "reactstrap";
import UserData from '../components/User/userData';
import { CSVLink } from "react-csv";
import UserProfile from '../components/User/userProfile';
import AdvancedSearch from "./AdvancedSearch";
import { Router, Switch, Redirect } from "react-router-dom";
import history from "../utils/history";
import PerfectScrollbar from "perfect-scrollbar";
import Pagination from "./Pagination";

var record, results, user, resData = undefined, newJob = false;
var ps, total=0, limit=1000, offset = 0, pageIndex=1;
var advSearchItems, activeRow;
const csvheaders = [
  { label: 'ID', key: 'id' },
  { label: 'Init', key:'init'},
  { label: 'SYSIX_ID', key: 'sysixid' },
  { label: 'Gold Name', key: 'goldname' },
  { label: 'Gold Address', key: 'goldaddress' },
  { label: 'ISO', key: 'iso' },
  { label: 'Match Type', key: 'matchtype' }
];
class Results extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.location.state) {
      if (this.props.location.state.jobid) {
        sessionStorage.setItem('jobid', this.props.location.state.jobid);
        newJob = true;
      }
    }
    // console.log(newJob);
    this.state = {
      modal1: false,
      modalid: '',
      originalWire: '',
      searchModal: false,
      jobid: (this.props.location.state) ? 
              this.props.location.state.jobid :
               (sessionStorage.getItem('jobid') ? sessionStorage.getItem('jobid') : ''),
      showResults: false,
      showingSpin: newJob ? true : false,
      searchDisabled: true,
      csvfilename : "",
      csvdata : [],
    };
    // initializing the total and page index values for pagination
    total = parseInt(sessionStorage.getItem('total') || 0);
    pageIndex = parseInt(sessionStorage.getItem('pageIndex') || 1);

    // retrieve user data from user profile
    user = UserProfile.getUserDetails();
    resData = undefined;
    this.props.updateScroll();
    this.formResultData = this.formResultData.bind(this);
    this.getTotalRecords = this.getTotalRecords.bind(this);
    // this.showOriginalWire = this.showOriginalWire.bind(this);
  }
  
  formResultData() {
    if (newJob) {
      total = 0;
      pageIndex = 1;
      offset = 0;
      resData = undefined;
      this.wiresSearchAPI();
    }
    if (resData)
      return;
    results = UserData.getCurrentResults();
    // console.log(results);
    if (results)
      if (results.length > 0) {
        // console.log(results);
        resData = {
          columns: [
            { label: 'ID', field: 'id', width: '10%'},
            { label: 'SYSIX ID', field: 'sysixid', width: '10%'},
            { label: 'Gold Name', field: 'goldname', width: '30%'},
            { label: 'Gold Address', field: 'goldaddress', width: '30%'},
            { label: 'ISO', field: 'iso', width: '10%'},
            { label: 'Match Type', field: 'matchtype', width: '10%'}
          ],
          rows:
            results.map((arr, key) => {
              var goldId = arr.init[0].split('|')[0];
              var res = arr.gold.result1;
              let rw = {
                key,
                id: <span className="text-blue-ul result-id" onClick={this.showOriginalWire.bind(this, key, 'modal1')}
                      title={arr.init[0]} searchvalue={arr.init[0]}>{goldId}</span>,
                sysixid: <span className="text-blue-ul result-id" onClick={this.geoSearchSLID.bind(this, res[0])}
                        title={res[0]} searchvalue={res[0]}>{res[0]}</span>,
                goldname: res[1] || "-",
                goldaddress: res[2] || "-",
                iso: res[3] || "-",
                // goldrow: 'result1',
                matchtype: <span className="text-btnblue-ul" searchvalue={parseInt(arr.match_type || 600)}>{arr.match_type || 600}</span>
                //onClick={this.showMore.bind(this, 'modal2', key)}
              }
              return rw;
            })
        }
      }
  }

  geoSearchSLID(sysixid){
    console.log(sysixid);
    this.props.history.push({
      pathname: '/admin/geosearch',
      state: { sysixid }
    });
  }

  handleChange = (event) => {
    if(event.target.value)
      this.setState({ jobid: event.target.value, searchDisabled:false });
    else
      this.setState({ jobid: event.target.value, searchDisabled:true });
  };


  closeModal(tabId) {
    if(activeRow)
      activeRow.classList.remove('activeRow')
    this.setState({
      [tabId]: false
    });
  }

  openModal(tabId) {
    this.setState({
    [tabId]: true
    });
  }

  
  showOriginalWire(key, modal, event) {
    //event.preventDefault();
    console.log(this.props);
    //this.props.preventScroll();
    if(activeRow)
      activeRow.classList.remove('activeRow')
    record = results[key];
    activeRow = event.target.parentElement.parentElement;
    activeRow.classList.add('activeRow');
    this.setState({
      [modal]: true,
      modalid: event.target.innerText,
      originalWire: record.init[4] + " " + record.init[5] + " " + record.init[6] + " " + record.init[7]
    });
  }

  getAdvancedSearchItems = (searchItems) =>{
    // get multiple search items from the AdvancedSearch child component
    advSearchItems  = searchItems;
  }

  wiresSearchAPI = () => {
    // If session expired or the browser tab is closed, later the page redirects to login page.
    if(!user){
      return (
        <Router history={history}>
          <Switch>
            <Redirect to="/login" />
          </Switch>
        </Router>
      );     
    }
    var searchObj = {};

    // Checking advanced search elements otherwise taking the jobid as search element.
    if(advSearchItems)
      searchObj = advSearchItems;
    else
      searchObj.job_id = this.state.jobid || null;
    var allNulls = true;
    // Checking whether any one element has the value
    Object.keys(searchObj).forEach(key => {
      if (searchObj[key] && allNulls) {
        allNulls = false;
      }
    });
    if (allNulls) {
      alert("No job id found to search.")
      return;
    }
    newJob = false;
    resData = undefined;
    UserData.setCurrentResults(undefined);
    this.setState({ showResults: false, showingSpin: true, searchDisabled: true });
    searchObj.client_name = "WF";
    searchObj.username = user.name;
    searchObj.is_agc = true;
    searchObj.offset = offset;
    searchObj.limit = limit;
    fetch('/searchwires_pagination', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(searchObj)
    }).then(
      res => {
        if (res.ok) {
          res.json().then(res => {
            console.log(res);
            if (res.error) {
              alert("Some error occured at MDM server. \n" + res.error.code);
            }
            else if (res.search_result.statusCode === 403){
              alert('Something went wrong. Please login again and try.')
           } 
            else {
              if (res.body.response && res.body.response.length === 0) {
                alert("No data found.");
              }
              results = res.body.response.sort((a, b) => parseInt(a.match_type || 600) > parseInt(b.match_type || 600) ? 1 : -1)
              total = res.body.total;
              UserData.setCurrentResults(results);
              sessionStorage.setItem('total', total);
              sessionStorage.setItem('pageIndex', pageIndex);
              sessionStorage.setItem('outputData', JSON.stringify(results));
              // this.formResultData();
              this.setState({ showResults: true, searchModal:false});
            }
          }, error => {
            alert(error);
          });
        } else {
          alert(res.statusText + " : " + res.status);
        }
        this.setState({ showingSpin: false, searchDisabled: false });
      });
  }

  prependZero = (num) => {
    if (num < 10)
      return "0" + num;
    else
      return num;
  }

  getFileName = () => {
    let d = new Date();
    let dformat = [
      d.getFullYear(),
      this.prependZero(d.getMonth() + 1),
      this.prependZero(d.getDate())
    ].join('') +
      '_' +
      [this.prependZero(d.getHours()),
      this.prependZero(d.getMinutes()),
      this.prependZero(d.getSeconds())].join('');

    // console.log("getCurrentDate : ", dformat);
    return "agc_"+ this.state.jobid + "_" + dformat + ".csv";
  }

  getTotalRecords() {
    var searchObj = {};
    // if(advSearchItems)
    //   searchObj = advSearchItems;
    // else
    //   searchObj.job_id = this.state.jobid || null;
    searchObj.job_id = this.state.jobid;
    searchObj.client_name = "WF";
    searchObj.username = user.name;
    searchObj.is_agc = true;
    this.setState({showingSpin:true});
    var csvdata = [];
    fetch('/searchWires', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: "POST",
      body: JSON.stringify(searchObj)
    }).then( res => {
      if (res.ok) {
        res.json().then(res => {
          this.setState({showingSpin:false});
          console.log(res);
          var all = res.body;
          csvdata = all.map((crow) => {
            // console.log(crow);
            var init = crow.init;
            var gRes = crow.gold.result1;
            return {
              init: init[4] + " " + init[5] + " " + init[6] + " " + init[7],
              id: init[0],
              sysixid: gRes[0],
              goldname: gRes[1],
              goldaddress: gRes[2],
              iso: gRes[3],
              matchtype: crow.match_type || 600
            };
          });
          this.setState({csvdata});
          document.getElementById('csv-link').click();
        });
      }
    });
  }

  componentDidMount() {
    var ele = document.getElementById('rTable');
    if(ele){
      var div = ele.parentElement;
      ps = new PerfectScrollbar(div,{
        suppressScrollY: true
      });
    }
    this.formResultData();
  }

  componentDidUpdate() {
    var ele = document.getElementById('rTable');
    if(ele){
      var div = ele.parentElement;
      ps = new PerfectScrollbar(div,{
        suppressScrollY: true
      });
    }
  }

  onPrevious = () => {
    offset = (pageIndex - 1) * limit;
    pageIndex = pageIndex - 1;
    // this.setState({pageIndex : this.state.pageIndex - 1});
    this.props.updateScroll();
    this.wiresSearchAPI();
  }
  onNext = () => {
    offset = (pageIndex) * limit;
    pageIndex = pageIndex + 1;
    // this.setState({pageIndex : this.state.pageIndex + 1});
    this.props.updateScroll();
    this.wiresSearchAPI();
  }

  searchJobCriteria = () => {
    offset = 0;
    total = 0;
    pageIndex = 1;
    this.wiresSearchAPI();
  }

  componentWillUnmount() {
    if (ps) {
      ps.destroy();
    }
  }

  render() {
    this.formResultData();
    var download = resData? true: false;
    // if (resData) {
    //   download = true;
    // }
    //  console.log(resData);  
    return (
      <React.Fragment>
          <div className={this.state.showingSpin ? "loading" : "loading hide"}>
            <i className="fa fa-spinner fa-pulse"></i>
          </div>
          <Row>
            <Col md="12">
              <div className="search-wrapper">
              <Form className="">
                  <Row>
                    <Col sm={6} md={4} className="mt-3">
                      <FormGroup row>
                        <Col sm={12}>
                          <Input type="text" name="jobid" id="jobid" placeholder="Job id"
                            value={this.state.jobid}
                            onChange={this.handleChange} />
                        </Col>
                      </FormGroup>
                    </Col>
                    <Col sm={6} className="">
                      <Button color="success" className="runmdm" disabled={this.state.searchDisabled}
                        onClick={this.searchJobCriteria}>  Search
                      </Button>
                      <Button color="success" className="runmdm" disabled={this.state.searchDisabled}
                        onClick={this.openModal.bind(this, 'searchModal')}> Advanced Search
                      </Button>
                      {/* <Button color="info" className="btnwhite searchbtn" disabled={this.state.searchDisabled}
                        onClick={this.openModal.bind(this, 'searchModal')}
                        >  Advanced Search
                        </Button> */}
                    </Col>
                  </Row>
                </Form>
              </div>
              <Card>
                <CardBody id="resultsTable">
                  <MDBDataTable
                   // searching = {true}
                    // entriesOptions={[5, 10, 15, 20, 25, 50, 100]}
                    // entries={10}
                    displayEntries={false}
                    hover
                    id="rTable"
                    data={resData}
                    sortRows={['matchtype','id', 'sysixid']}
                    // onPageChange = {(value) => this.onPagination(value)}
                    className="bordered"
                    //paging={false}
                    paging={true}
                  />
                </CardBody>
                {/*<CardFooter style={{marginTop:"-20px"}}>
                  <Pagination 
                    total={total}
                    pageIndex={pageIndex}
                    perPage={limit}
                    onPrevious = {this.onPrevious}
                    onNext = {this.onNext}
                  >
                  </Pagination>
                </CardFooter> */}            
              </Card>
              <Button color="primary" className="btn btn-primary pull-right" 
                   disabled={!download} onClick={this.getTotalRecords}>
                Download CSV <span className="padding2"> <i className="fa fa-download"></i></span>
              </Button>
              <CSVLink id="csv-link" data={this.state.csvdata} headers={csvheaders} className="btn btn-primary pull-right hide"
                asyncOnClick={true} filename={this.state.csvfilename}
                onClick={(event, done) => {
                  this.setState({
                    csvfilename: this.getFileName()
                  }, () => {
                    done()
                  });
                }}
              >
                CSV
              </CSVLink>
            </Col>
            <div className="clearfix"></div>
            <Modal isOpen={this.state.modal1} toggle={this.closeModal.bind(this, 'modal1')} size="lg">
              <ModalHeader toggle={this.closeModal.bind(this, 'modal1')}>
                Orginal Input : <span>{this.state.modalid}</span>
              </ModalHeader>
              <ModalBody>
                {this.state.originalWire}
              </ModalBody>
            </Modal>
            <Modal isOpen={this.state.searchModal} toggle={this.closeModal.bind(this, 'searchModal')}>
              <ModalHeader toggle={this.closeModal.bind(this, 'searchModal')}>
                More search criteria..
              </ModalHeader>
              <ModalBody>
                <AdvancedSearch jobid={this.state.jobid} sendSearchItems={this.getAdvancedSearchItems}/>
              </ModalBody>
              <ModalFooter>
                <Button color="info" className="btnwhite searchbtn ml-auto" onClick={this.searchJobCriteria}>Search</Button>
              </ModalFooter>
            </Modal>
          </Row>
      </React.Fragment>
    );
  }
}
export default Results;
