var UserData = (function() {
    var currentResults = sessionStorage.getItem('outputData')? JSON.parse(sessionStorage.getItem('outputData')) : undefined;
    var ipWiresList = sessionStorage.getItem('loadArray')? JSON.parse(sessionStorage.getItem('loadArray')) : undefined;
    var newJobId = undefined;
    var allJobsList = sessionStorage.getItem('jobsArray')? JSON.parse(sessionStorage.getItem('jobsArray')) : undefined;

    var setNewJobId = function(jobId){
        newJobId = jobId;
    }

    var getNewJobId = function(){
        return newJobId;
    }

    var setCurrentResults = function(results) {
        currentResults = results;
    }

    var getCurrentResults = function() {
      //  console.log(currentResults);
        return currentResults;
    }

    var setIPWiresList = function(wiresList){
        ipWiresList = wiresList;
    }

    var getIPWiresList = function() {
        return ipWiresList;
    }

    var setAllJobsList = function(jobsList){
        allJobsList = jobsList;
    }

    var getAllJobsList = function(){
        return allJobsList;
    }

    var clear = function() {
        currentResults = undefined;
        ipWiresList = undefined;
        newJobId = undefined;
        allJobsList = undefined;
    }

    return {
        setNewJobId : setNewJobId,
        getNewJobId : getNewJobId,
        setCurrentResults : setCurrentResults,
        getCurrentResults : getCurrentResults,
        setIPWiresList: setIPWiresList,
        getIPWiresList: getIPWiresList,
        setAllJobsList : setAllJobsList,
        getAllJobsList : getAllJobsList,
        clear: clear
    }
})();

export default UserData;