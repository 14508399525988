var UserProfile = (function(){
    var user = sessionStorage.getItem('user')? JSON.parse(sessionStorage.getItem('user')) : undefined;

    var setUserDetails = function(userObj) {
        user = userObj;
    }

    var getUserDetails = function() {
        return user;
    }


    var clear = function(){
        user = undefined;
    }

    return {
        setUserDetails: setUserDetails,
        getUserDetails: getUserDetails,
        clear: clear
    }
})();

export default UserProfile;