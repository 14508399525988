
import React from "react";
import Login from "views/Login";

class LoginLayout extends React.Component {
// renders the login page
  render() {
    return (
      <div className="wrapper">
      
        <div className="login-panel">
       
          <Login/>
     
        </div>
       
      </div>
    );
  }
}

export default LoginLayout;
