import React from 'react';
import pagenotfound from '../assets/img/404.jpg';
import {
    Card,
    CardBody,
    Row,
    Col
  } from "reactstrap";

const notfound = () => (
    <div className="content">
          <Row>
            <Col md="12">
              <Card>               
                <CardBody>
                <img src={pagenotfound} className="page-notfound" alt="NotFound-img"/>
                </CardBody>
                </Card>
            </Col>         
          </Row>
        </div>     
    );

export default notfound;