import React from "react";
import { Map, Marker, Popup, TileLayer, Circle } from "react-leaflet";
import { MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBContainer } from
"mdbreact";
import blueIcon from "../assets/img/blue2.png"
import redIcon from "../assets/img/red2.png";
import greenIcon from "../assets/img/green2.png"
import purpleMarker from "../assets/img/purple3.png";
import clientMarker from "../assets/img/yellow.png";
import PerfectScrollbar from "perfect-scrollbar";
import { Icon } from "leaflet";

const generalIcon = new Icon({
    iconUrl: redIcon,
    iconSize: [20, 30],
    className:"goldMarker"
  });
const sysixIcon = new Icon({
    iconUrl: blueIcon,
    iconSize: [20, 30]
});
const clientIcon = new Icon({
    iconUrl: clientMarker,
    iconSize: [20, 30]
  });
const similarIcon = new Icon({
    iconUrl: greenIcon,
    iconSize: [20, 30]
});
const purpleIcon = new Icon({
    iconUrl: purpleMarker,
    iconSize: [20, 30]
})
var specialIDs = ["SLGd29f186b72ca741b5d14c173c022ae2e",
                "SLGb798485c11d301c382df63d73cc08b48",
                "SLGf7c646ae0b2da2a75387e37c915fdc55",]
var samePointersSet=[];
var ps, ignoreTypes = ["POINT_OF_INTEREST", "ESTABLISHMENT"];
var gold, markers=[], sysixMerchants=[], clientMerchants=[], similarMerchants=[];
class LeafletOSM extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            popUp:false,
        }
        gold = undefined;
        this.mapRef = React.createRef();
        this.popupRef = React.createRef();
        this.setMarkers = this.setMarkers.bind(this);
        this.setMarkers();
    }

   
    setActiveMark = (org_mark) => {
        // console.log(mark);
        if(org_mark){
            samePointersSet = [];
            samePointersSet = markers.filter (mark =>
                                mark.latitude === org_mark.latitude && 
                                mark.longitude === org_mark.longitude &&
                                mark.type === org_mark.type
                                )
        //    samePointersSet.unshift(org_mark);
            // if(org_mark.general){
            //     samePointersSet = [];
            //     samePointersSet.push(org_mark)
            // }
            // else{
            //     var markers;
            //     if(org_mark.type === 'Sysix')
            //         markers = sysixMerchants;
            //     else if(org_mark.type === 'Client')
            //         markers = clientMerchants;
            //     else
            //         markers = similarMerchants;

            //     samePointersSet = markers.filter (mark => mark.latitude === org_mark.latitude && mark.longitude === org_mark.longitude)
            // }
            this.setState({popUp:!this.state.popUp});
        }
        else {
            samePointersSet = [];
            this.setState({popUp:!this.state.popUp});
        }
       
    }

    setMarkers(){
        markers = this.props.markers;
        // console.log(markers);
        gold = markers.filter(mark => mark.type === "gold")[0];
        sysixMerchants = markers.filter(mark => 
            (mark.type==='sysix' && (mark.latitude!== gold.latitude && mark.longitude!==gold.longitude)));
        clientMerchants = markers.filter(mark => 
            (mark.type==='client' && (mark.latitude!== gold.latitude && mark.longitude!==gold.longitude)));
        similarMerchants = markers.filter(mark => 
            (mark.type==='similar' && (mark.latitude!== gold.latitude && mark.longitude!==gold.longitude)));
        if(clientMerchants.length > 0)
            clientMerchants.forEach(client => {
                sysixMerchants = sysixMerchants.filter(sysix => 
                    (sysix.latitude!== client.latitude && sysix.longitude!==client.longitude))
            })
    }

    componentDidUpdate(){
        // var map = this.mapRef.current;
        // console.log(map);
        // map.setView();
        setTimeout(() => {
            if (navigator.platform.indexOf("Win") > -1 && this.popupRef.current) {
                ps = new PerfectScrollbar(this.popupRef.current, {
                    suppressScrollX: true
                  });
            }
        }, 100); 
    }

    getGoldMarker(){
        var markerImg = generalIcon;
        if(specialIDs.includes(gold.sl_id)){
            markerImg = purpleIcon;
            markerImg.options.className = "goldMarker";
        }
        return  <Marker icon = {markerImg}
        position={[gold.latitude, gold.longitude]}
        onClick={() => {this.setActiveMark(gold);}}
        >
        <Circle 
            center={{lat:gold.latitude, lng: gold.longitude}}
            fillColor="blue" 
            radius={parseInt(this.props.radius)}
            opacity={0.3}
            fillOpacity={0.1}
            weight={2}
        /> 
    </Marker>
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1 && ps) {
          ps.destroy();
        }
      } 

    render () {
        this.setMarkers();
        var center;
        if(gold){
            center = {lat: gold.latitude, lng: gold.longitude}
        }
        //scrollWheelZoom={false}
        var samePointersSetLength = samePointersSet.length;
        // console.log(samePointersSet);
        return (
            <React.Fragment>
            <Map id="geoMap" center={center} zoom={15} ref={this.mapRef} maxZoom={21} 
                // scrollWheelZoom={false}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
               {
                   this.getGoldMarker() 
               }
              
                {sysixMerchants.map((mark, index) => {
                    var markerImg = sysixIcon;
                    if(specialIDs.includes(mark.sl_id)){
                        markerImg = purpleIcon;
                    }
                    return <Marker icon = {markerImg} key={index}
                        position={[mark.latitude, mark.longitude]}
                        onClick={() => {this.setActiveMark(mark);}}
                    />
                })}
                {clientMerchants.map((mark, index) => {
                     var markerImg = clientIcon;
                     if(specialIDs.includes(mark.sl_id)){
                         markerImg = purpleIcon;
                     }
                     return <Marker icon = {markerImg} key={index}
                    position={[mark.latitude, mark.longitude]}
                    onClick={() => {this.setActiveMark(mark);}}
                    />
                })}
                {similarMerchants.map((mark, index) => (
                    <Marker icon = {similarIcon} key={index}
                    position={[mark.latitude, mark.longitude]}
                    onClick={() => {this.setActiveMark(mark);}}
                    />
                ))}
                
                {samePointersSetLength > 0 && (
                    <Popup
                    style = {{bottom:"19px", left:"-83px"}}
                    position={[
                        samePointersSet[0].latitude,
                        samePointersSet[0].longitude
                    ]}
                    onClose={() => {
                        this.setActiveMark(null);
                    }}
                    >
                    <div>
                        <MDBContainer>
                            <MDBCarousel
                                activeItem={1}
                                length={samePointersSet.length}
                                showControls={samePointersSetLength > 1}
                                showIndicators={false}
                                className="z-depth-1"
                                slide
                                interval={false}
                            >
                                <MDBCarouselInner>
                                    <div ref={this.popupRef} className="popup">
                                    {
                                        samePointersSet.map((mark, ind) => {
                                            var itemId = ind + 1;
                                            var category = mark.category;
                                            if(Array.isArray(category)){
                                                category = category.filter(val => !ignoreTypes.includes(val))
                                                category = category.join(', ');
                                                category = category.toLowerCase();
                                            }
                                            
                                            return <MDBCarouselItem itemId={itemId} key={ind}>
                                                        <div>
                                                            <div>
                                                                <span className="float-right pl-2">{itemId} / {samePointersSetLength}</span>
                                                            </div>
                                                            <h5 style={{fontSize:"1.25em"}}>{mark.gold_name}</h5>
                                                            <p>{mark.gold_address}</p>
                                                            {/* <p className="busi-category">Merchant type: {mark.type}</p> */}
                                                            <p className="busi-category" style={{marginTop:"0px !important"}}>{category}</p>
                                                        </div>
                                                    </MDBCarouselItem>                                      
                                        })
                                    }
                                    </div>                                   
                                </MDBCarouselInner>
                            </MDBCarousel>
                        </MDBContainer>
                    </div>
                </Popup>
                )}
            </Map>
           </React.Fragment>
        );
    }
}
export default LeafletOSM;