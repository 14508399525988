import React from "react";
//import download from '../assets/img/icons/download.svg';
import DataTable from 'react-data-table-component';
import trash from '../assets/img/icons/trashicon.svg';
import UserData from "../components/User/userData";
import UserProfile from '../components/User/userProfile';
import { Card, CardBody, Row, Col, Button, Input, Modal, ModalHeader, ModalFooter, ModalBody } from "reactstrap";
import { Router, Switch, Redirect } from "react-router-dom";
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";
import history from "../utils/history";

var data, jobsList, user, activeRow;
class Jobs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data : undefined,
      dd1: false,
      updateNameModal: false,
      deleteJobId: '',
      deleteJobName: '',
      noDataText : 'Loading Data..',
      showingSpin : true,
      editName : undefined,
      newJobName : undefined,
      deleteConfirmModal : false,
      expicons: {
        collapsed: <svg className="svg-icon" viewBox="0 0 20 20">
          <path d="M14.613,10c0,0.23-0.188,0.419-0.419,0.419H10.42v3.774c0,0.23-0.189,0.42-0.42,0.42s-0.419-0.189-0.419-0.42v-3.774H5.806c-0.23,0-0.419-0.189-0.419-0.419s0.189-0.419,0.419-0.419h3.775V5.806c0-0.23,0.189-0.419,0.419-0.419s0.42,0.189,0.42,0.419v3.775h3.774C14.425,9.581,14.613,9.77,14.613,10 M17.969,10c0,4.401-3.567,7.969-7.969,7.969c-4.402,0-7.969-3.567-7.969-7.969c0-4.402,3.567-7.969,7.969-7.969C14.401,2.031,17.969,5.598,17.969,10 M17.13,10c0-3.932-3.198-7.13-7.13-7.13S2.87,6.068,2.87,10c0,3.933,3.198,7.13,7.13,7.13S17.13,13.933,17.13,10"></path>
        </svg>,
        expanded: <svg className="svg-icon expand" viewBox="0 0 20 20">
          <path d="M14.776,10c0,0.239-0.195,0.434-0.435,0.434H5.658c-0.239,0-0.434-0.195-0.434-0.434s0.195-0.434,0.434-0.434h8.684C14.581,9.566,14.776,9.762,14.776,10 M18.25,10c0,4.558-3.693,8.25-8.25,8.25c-4.557,0-8.25-3.691-8.25-8.25c0-4.557,3.693-8.25,8.25-8.25C14.557,1.75,18.25,5.443,18.25,10 M17.382,10c0-4.071-3.312-7.381-7.382-7.381C5.929,2.619,2.619,5.93,2.619,10c0,4.07,3.311,7.382,7.381,7.382C14.07,17.383,17.382,14.07,17.382,10"></path>
        </svg>
      }
    };
    user = UserProfile.getUserDetails();
    this.dropdownToggle = this.dropdownToggle.bind(this);
    this.searchJobId = this.searchJobId.bind(this);
    this.getAllJobsList = this.getAllJobsList.bind(this);
    this.editJobName = this.editJobName.bind(this);
    this.saveNewJobName = this.saveNewJobName.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    // this.props.updateScroll();
    // this.getAllJobsList();
  }

  componentDidMount() {
    this.props.updateScroll();
    this.getAllJobsList();
  }
  getAllJobsList(){
	if(!user){
      return (
        <Router history={history}>
          <Switch>
            <Redirect to="/login" />
          </Switch>
        </Router>
      );     
    }
    var clientObj = {};
    clientObj.client_name="WF";
    clientObj.username = user.name;
    clientObj.is_agc=true;
    this.setState({showingSpin:true});
    fetch('/getAllUserJobs', {
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body:JSON.stringify(clientObj),
    method: "POST"}).then(
      res => {
        this.setState({showingSpin:false});
        if(res.ok) {
          res.json().then(
            res => {
              console.log(res);
              if(res.error){
                res.body = [];
                alert("Some error occured at MDM server. \n" + res.error.code);
                this.setState({noDataText : 'No data found'});
              }
              
              var jobs = res.body;
              // console.log(jobs);
              data = jobs.map((job) => {
                return {user: job[0], jobid: job[1],
                       jobname:job[2], time:job[3] ? job[3].replace('T', ' ') : "",
                       status:job[4], progress:job[6]};
              });
              if(data.length > 0){
                jobsList = data;
                UserData.setAllJobsList(data);
                sessionStorage.setItem('jobsArray', JSON.stringify(data));
                this.setState({noDataText : 'done', editName:false});
              }else{
                this.setState({noDataText : 'No data found'});
              }
              
            },
            error => {
              alert(error);
              this.setState({noDataText : 'No data found'});
            }
          );
        } else {
          alert(res.statusText + " : " + res.status);
          this.setState({noDataText : res.statusText});
        }
    });
  }

  searchJobId(status, event){
    if(status === 'READY'){
      var jobid = event.target.innerText;
      //console.log(jobid);
      this.props.history.push({
        pathname: '/admin/results',
        state: { jobid }
      });
    } 
  }

  editJobName(jobid){
    this.setState({editName : jobid});
    setTimeout(function() {
      document.getElementById(jobid).focus();
    }, 200)
  
  }

  handleNameChange = (event) => {
    var newJobName = event.target.value;
    console.log(newJobName);
    this.setState({newJobName});
  }

  keyPress = (e, old_name) => {
    if(e.keyCode === 13){
      //  console.log('value', e.target.value, old_name);
       this.saveNewJobName(old_name, e);
       // put the login here
    }
  }

  saveNewJobName(old_name, event){
    var jobName = event.target.value;
    // console.log(old_name, jobName);
    if(old_name === jobName){
      this.setState({editName : undefined});
      return;
    }
    var clientObj = {};
    clientObj.client_name="WF";
    clientObj.username = user.name;
    clientObj.job_id = this.state.editName;
    clientObj.job_name = jobName;
    clientObj.is_agc=true;
    this.setState({showingSpin:true, newJobName: jobName});
    fetch('/updateJobName', {
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body:JSON.stringify(clientObj),
    method: "POST"}).then(
      res => {
        this.setState({showingSpin:false});
        if(res.ok) {
          res.json().then(
            res => {
              console.log(res);
              if(res.error){
                res.body = [];
                alert("Some error occured at MDM server. \n" + res.error.code);
                this.setState({editName : undefined});
              }
              else{
                this.getAllJobsList();
                this.setState({editName : undefined, updateNameModal: true, newJobName:undefined});    
              }                      
            },
            error => {
              alert(error);
              this.setState({editName : undefined});
            }
          );
        } else {
          alert(res.statusText + " : " + res.status);
          this.setState({editName : undefined});
        }
    });   
  }

  deleteJob () {
    var clientObj = {};
    clientObj.client_name="WF";
    clientObj.username = user.name;
    clientObj.job_id = this.state.deleteJobId;
    clientObj.is_agc=true;
    this.setState({showingSpin:true});
    fetch('/deleteJob', {
      headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body:JSON.stringify(clientObj),
    method: "POST"}).then(
      res => {
        this.setState({showingSpin:false});
        if(res.ok) {
          res.json().then(
            res => {
              console.log(res);
              this.closeModal('deleteConfirmModal');
              if(res.error){
                res.body = [];
                alert("Some error occured at MDM server. \n" + res.error.code);
              }
              else{
                this.getAllJobsList();
                this.setState({successModal:true});
              }            
            },
            error => {
              alert(error);
            }
          );
        } else {
          alert(res.statusText + " : " + res.status);
        }
    });
  }

  dropdownToggle() {
    this.setState({
      dd1: !this.state.dd1
    });
  }
  closeModal(tabId) {
    if(activeRow)
      activeRow.classList.remove('activeJob')
    this.setState({
      [tabId]: false
    });
  }

  showDeleteModal(modal, jobid, jobname, event) {
    if(activeRow)
      activeRow.classList.remove('activeJob')
    console.log(event.target);
    activeRow = event.target.parentElement.parentElement;
    activeRow.classList.add('activeJob');
    this.setState({
      [modal]: true,
      deleteJobId: jobid,
      deleteJobName: jobname
    });
  }

  showModal(modal, event) {
    if(activeRow)
      activeRow.classList.remove('activeJob')
    console.log(event.target);
    activeRow = event.target.parentElement.parentElement;
    activeRow.classList.add('activeJob');
    this.setState({
      [modal]: true,
    });
  }

  getSearchValue = (event) => {
    var val = event.target.value;
    // data.forEach(row => {
    //   console.log(JSON.stringify(row))
    // })
    if(val){
      console.log(val);
      val = val.toLowerCase();
      data = jobsList.filter(row => JSON.stringify(row).toLowerCase().indexOf(val) > -1);
      console.log(data);
    }
    else
      data = jobsList;
    this.setState({ search: !this.state.search });
  }

  customSort = (rows, field, direction) => {
    if (direction === 'asc') 
      if(field === 'progress'|| field === 'row')
        rows.sort((a,b) => parseInt(a[field]) > parseInt(b[field]) ? 1 : -1);
      else
        rows.sort((a,b) => a[field].toLowerCase() > b[field].toLowerCase() ? 1 : -1);
    else
      if(field === 'progress' || field === 'row')
        rows.sort((a,b) => parseInt(a[field]) < parseInt(b[field]) ? 1 : -1);
      else
        rows.sort((a,b) => a[field].toLowerCase() < b[field].toLowerCase() ? 1 : -1);
    return rows.slice(0);
  };

  render() {
    //this.getAllJobsList();
    if(data){
      data = data.sort((a,b) => a.time < b.time ? 1 : -1);
      data = data.map((job, ind) => {
        job.row = ind+1;
        return job;
      })
    }
    const columns = [
      {
        name: 'Row',
        selector: 'row',
        sortable: true,
        maxWidth: "80px",
        cell: row => <div style={{ fontWeight: 700 }}>{row.row}</div>,
      },
      {
        name: 'User',
        selector: 'user',
        sortable: true,
        maxWidth: "150px",
        cell: row => <div style={{ fontWeight: 700 }}>{row.user}</div>,
      },
      {
        name: 'Job Id',
        selector: 'jobid',
        sortable: true,
        cell: row => <span className={row.status} title="search this job id" onClick={this.searchJobId.bind(this, row.status)}>{row.jobid}</span>
      },
      {
        name: 'Job Name',
        selector: 'jobname',
        sortable: true,
        // sortFunction: (rowA, rowB) => {
        //   console.log(rowA);
        //   return rowA.jobname > rowB.jobname ? 1 : -1;
        // },
        // sortFunction : (a,b) => a.jobname.toLowerCase() < b.jobname.toLowerCase(),
        cell: row => <span><span className={this.state.editName === row.jobid? "hide" : ""} onClick={this.editJobName.bind(this, row.jobid)} title="Edit Job Name" >{row.jobname}</span>
                            <Input id={row.jobid} className={this.state.editName === row.jobid? "" : "hide" }
                              onBlur={this.saveNewJobName.bind(this, row.jobname)}
                              onKeyDown={(e) => this.keyPress(e, row.jobname)} defaultValue={this.state.newJobName || row.jobname} /></span>
      },
      {
        name: 'Submit Time',
        selector: 'time',
        sortable: true,
        maxWidth: '150px'
        // cell: row => <div>{row.time}</div>,
      },
      {
        name: 'Status',
        // selector: 'status',
        selector: 'progress',
        sortable: true,
        // maxWidth: "130px",
        maxWidth: "210px",
        sortFunction: (rowA, rowB) => parseInt(rowA.progress) - parseInt(rowB.progress),
        cell: row => <Progress percent={row.progress} status={row.status==="ERROR"? "error": ""}
                        theme={{
                          error:{
                            symbol:<i class="fas fa-exclamation-circle progress-error-symbol"></i>,
                            color:'#de5950'
                          },
                          default:{
                            trailColor:'#d0eafb'
                          }
                        }}/>
      },
      {
        name: 'Actions',
        selector: 'action',
        sortable: true,
        maxWidth: "120px",
        cell: row =>  <span className="trash"   onClick={this.showDeleteModal.bind(this, 'deleteConfirmModal', row.jobid, row.jobname)}>
          <img src={trash} alt="trash" style={{"width":"25px"}}/> Delete</span>
      },
    ];
    // const ExpanableComponent = ({ row }) => <div className="p-10"><h4 className="t-head">Search Parameters for Job Id  {row.jobid} </h4>
    //   <p> <strong>Application:</strong> {row.application}</p>
    //   <p> <strong>Phone:</strong> {row.phone}</p>
    //   <p> <strong>Party:</strong> {row.party}</p>
    //   <p> <strong>Systems:</strong> {row.systems}</p>
    //   <p> <strong>Tables:</strong> {row.tables}</p>
    // </div>;
    return (
      <React.Fragment>
        <div className={this.state.showingSpin ? "loading" : "loading hide"} id="loadingSpin">
          <i className="fa fa-spinner fa-pulse"></i>
        </div>  
        <div className="place-right">
          <Button color="success" onClick={this.getAllJobsList} className="runmdm">Refresh</Button>           
        </div>
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <div>
                  <div className="jobsSearchBox">
                    <span className="search-icon"></span>
                    <input type="text" name="jobsSearch" onChange={this.getSearchValue} placeholder="Search"></input>
                  </div>
                </div>
                <div className="bordered">
                  <DataTable
                    className="table table-hover dataTable"
                    columns={columns}
                    data={data}
                    // expandableRows
                    // expandableIcon={this.state.expicons}
                    // expandableRowsComponent={<ExpanableComponent />}
                    pagination={true}
                    noDataComponent = {this.state.noDataText}
                    defaultSortField	= "time"
                    defaultSortAsc = {false}
                    sortFunction = {this.customSort}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>         
        </Row>
      <Modal isOpen={this.state.updateNameModal} toggle={this.closeModal.bind(this, 'updateNameModal')}>
        <ModalHeader toggle={this.closeModal.bind(this, 'updateNameModal')}>
         Job Name Update Status:
        </ModalHeader>
        <ModalBody>
          Success
          {/* {this.state.updateJobName} */}
        </ModalBody>
      </Modal>
      <Modal isOpen={this.state.deleteConfirmModal} toggle={this.closeModal.bind(this, 'deleteConfirmModal')} size="lg">
          <ModalHeader toggle={this.closeModal.bind(this, 'deleteConfirmModal')}>
          Delete Job: {this.state.deleteJobName}
          </ModalHeader>
          <ModalBody>
            Are you sure you want to delete this Job?
            <br /> <br />
            <i style={{color:"#ff5151"}}>Deleting this job will delete all the associated data in the database.</i>
            {/* {this.state.updateJobName} */}
          </ModalBody>
          <ModalFooter>
            <Button color="info" className="btnwhite searchbtn ml-auto" onClick={this.deleteJob}>Confirm</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.successModal} toggle={this.closeModal.bind(this, 'successModal')}>
          <ModalHeader toggle={this.closeModal.bind(this, 'successModal')}>
          Success!
          </ModalHeader>
          <ModalBody>
             <b>{this.state.deleteJobName}</b> job is deleted successfully.
            {/* {this.state.updateJobName} */}
          </ModalBody>
        </Modal>
    </React.Fragment>
    );
  }
}

export default Jobs;
